import { MyMotion } from '@autohaus-range/my-motion';
import { isScullyRunning } from '@scullyio/ng-lib';
import { environment } from '../../environments/environment';

/**
 * Sets up the auto uncle integration according
 * to https://www.autouncle.de/de/customers/hylqcKlkGe/website_script_doc
 */
export function addAutoUncle(): void {
  if (isScullyRunning()) {
    return;
  }
  const script = document.createElement('script');
  script.textContent = `
    window.AuEmbed=window.AuEmbed || {
      pushedUrls:[],
      pushUrl: function(url){AuEmbed.pushedUrls.push(url)}
    };

    window.AuEmbed.config = {
      id: "${environment.autoUncle.configurationId}"
    };

    document.head.querySelector("meta[name=viewport]")||console.warn(
      'The AutoUncle Universal Script cannot detect any tag. Make sure that the script is added after this tag!'
    );
    var d = {
      depId: AuEmbed.config.departmentId,
      device: window.matchMedia("only screen and (max-width: 760px)").matches?"mobile":"desktop",
      pageType: AuEmbed.config.pageType,
      url: AuEmbed.config.previewUrl || document.location.href
    },
    s = document.createElement("script");
    s.async = !0,
    s.src = "https://www.autouncle.de/de/customers/"+encodeURIComponent(AuEmbed.config.id)+"/website_script.js?d="+encodeURIComponent(JSON.stringify(d)),
    s.id="au-cweb-script",
    h=document.getElementsByTagName("head")[0],
    h.insertBefore(s, h.lastChild);
  `;
  script.type = 'text/javascript';
  const body = document.querySelector('body');
  body?.appendChild(script);
}

export function isAutoUncleScriptActive(): boolean {
  return 'auws' in window && typeof (window as any).auws?.openModal === 'function';
}

export function waitForAutoUncleScript(): Promise<void> {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (isAutoUncleScriptActive()) {
        clearInterval(interval);
        resolve();
      }
    }, 500);
  });
}

export function openAutoUnclePopup(
  car: MyMotion.Car,
  leadSource: 'vehicle_detail_page_header' | 'vehicle_detail_page_financing'
): void {
  (window as any).auws.openModal((window as any).auws.ModuleTypes.TRADE_IN, {
    stockId: car.id,
    sellerUrl: location.href,
    customData: {
      leadSource
    }
  });
}
