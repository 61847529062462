import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { GalleryItem } from 'ng-gallery';
import * as PhotoSwipe from 'photoswipe';
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

interface OpenGalleryEvent {
  images: GalleryItem[];
  selectedIndex: number;
}

@Component({
  selector: 'app-photoswipe',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './photoswipe.component.html',
  styleUrls: ['./photoswipe.component.scss']
})
export class PhotoswipeComponent {
  private static OPEN_EVENT = 'photoswipe-open';

  static open(images: GalleryItem[], selectedIndex = 0): void {
    window.dispatchEvent(
      new CustomEvent<OpenGalleryEvent>(PhotoswipeComponent.OPEN_EVENT, {
        detail: {
          images,
          selectedIndex
        }
      })
    );
  }

  constructor() {
    window.addEventListener(PhotoswipeComponent.OPEN_EVENT, (evt: Event) => {
      const data = (evt as CustomEvent<OpenGalleryEvent>).detail;
      this.open(data.images, data.selectedIndex);
    });
  }

  open(images: GalleryItem[], selectedIndex = 0) {
    const pswpElement = document.querySelector('.pswp');

    if (!(pswpElement instanceof HTMLElement)) {
      console.warn(`Did not find element with class 'pswp'`);
      return;
    }

    const items: PhotoSwipe.Item[] = images.map(image => {
      return {
        src: image.data?.src,
        w: 1600,
        h: 1200
      };
    });

    const options: PhotoSwipe.Options = {
      index: selectedIndex
    };

    const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
    gallery.init();
  }
}
